@tailwind base;
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  color: #020217;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
